<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="ownerHeaders"
        :items="owners"
        :expanded.sync="expanded"
        item-key="Name"
        hide-default-footer
        disable-pagination
        class="elevation-1"
      >
        <template v-slot:item="{ item, expand, isExpanded }">
          <tr
            class="height"
            style="word-break: break-word"
            @click="expand(!isExpanded)"
          >
            <td class="d-block d-sm-table-cell">
              {{ item.Name }}
              <v-icon v-if="isExpanded">mdi-chevron-up</v-icon>
              <v-icon v-if="!isExpanded">mdi-chevron-down</v-icon>
            </td>
          </tr>
        </template>

        <template v-slot:expanded-item="{ item }">
          <v-container>
            <v-card>
              <v-data-table
                :items="item.Questions"
                :headers="actionHeaders"
                hide-default-footer
                disable-pagination
                dense
              >
              </v-data-table>
            </v-card>
          </v-container>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["expandOwners"],
  data: () => ({
    expanded: [],
    owners: [],
    names: [],
    allData: [],
    ownerHeaders: [
      { text: "Action Owners", value: "entry", sortable: true },
      // { text: "", value: "" },
    ],
    actionHeaders: [
      { text: "Question", value: "Question", sortable: false },
      { text: "Detail", value: "Detail", sortable: false },
      { text: "Due By", value: "Due", sortable: false },
      { text: "Status", value: "Status", sortable: false },
    ],
  }),

  methods: {
    debug() {
      console.log(this.owners);
      console.log(this.expanded);
      console.log(this.expandOwners);
    },

    createNewEntry(data, questionNum) {
      if (questionNum == 0) {
        var detail = data.Opportunity;
      } else if (questionNum == 1) {
        var detail = data.Discussion;
      } else {
        var detail = data.Description;
      }

      let newObj = {
        Question: questionNum,
        Detail: detail,
        Due: data.Due,
        Status: data.Status,
        Name: data.Owner.Name,
      };

      return newObj;
    },

    addNewEntry(actionData) {
      for (let owner in this.owners) {
        if (this.owners[owner].Name == actionData.Name) {
          this.owners[owner].Questions.push(actionData);
        }
      }
    },

    refactor() {
      for (let question in this.allData) {
        // for each question
        for (let item in this.allData[question]) {
          // for each entry
          if (this.names.includes(this.allData[question][item].Owner.Name)) {
          } else {
            this.owners.push({
              Name: this.allData[question][item].Owner.Name, // creates object for action owner if unmade
              Questions: [], // holds all related action owner questions
            });
            this.names.push(this.allData[question][item].Owner.Name);
          }
          let newObj = this.createNewEntry(
            this.allData[question][item],
            String(parseInt(question, 10) + 1)
          );
          this.addNewEntry(newObj);
        }
      }
    },

    all() {
      for (let entry in this.owners) {
        this.expanded.push(this.owners[entry]);
      }
    },
    none() {
      this.expanded = [];
    },
  },

  watch: {
    expandOwners: {
      handler() {
        if (this.expandOwners) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },

  computed: {
    q1Data() {
      return this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunities;
    },
    q2Data() {
      return this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunityActions;
    },
    q3Data() {
      return this.$store.state.prebidPlan.PreBidPlan.DigitalValueStories;
    },
    q4Data() {
      return this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices;
    },
  },

  mounted() {
    this.allData = [this.q1Data, this.q2Data, this.q3Data, this.q4Data];
    this.refactor();
    if (this.expandOwners) {
      this.all;
    } else {
      this.none;
    }
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
;
