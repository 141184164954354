<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        class="elevation-1"
        disable-pagination
        dense
      >
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    headers: [
      { text: "Question", value: "Question", sortable: false },
      { text: "Answered?", value: "Answered", sortable: false },
    ],

    items: [
      { Question: "Question 1", Answered: null },
      { Question: "Question 2", Answered: null },
      { Question: "Question 3", Answered: null },
      { Question: "Question 4", Answered: null },
      { Question: "Question 5", Answered: null },
    ],
  }),

  methods: {
    debug() {
      console.log(this.questionData);
      console.log(this.actionData);
      console.log(this.storyData);
      console.log(this.serviceData);
      console.log(this.contractData);
    },
  },

  mounted() {
    this.items = [
      {
        Question: "Have digital opportunities been considered?",
        Answered: this.questionData,
      },
      {
        Question:
          "Have actions been added from discussing with your digital champions?",
        Answered: this.actionData,
      },
      { Question: "Have value stories been added?", Answered: this.storyData },
      {
        Question: "Have the 'Ready to Go' services been considered?",
        Answered: this.serviceData,
      },
      {
        Question: "Has the contract been checked?",
        Answered: this.contractData,
      },
    ];
  },

  computed: {
    questionData() {
      return this.$store.state.prebidPlan.PreBidPlan.DigitalOpportunities[0]
        ? "Yes"
        : "No";
    },
    actionData() {
      return this.$store.state.prebidPlan.PreBidPlan
        .DigitalOpportunityActions[0]
        ? "Yes"
        : "No";
    },
    storyData() {
      return this.$store.state.prebidPlan.PreBidPlan.DigitalValueStories[0]
        ? "Yes"
        : "No";
    },
    serviceData() {
      return this.$store.state.prebidPlan.PreBidPlan.ReadyDigitalServices[0]
        ? "Yes"
        : "No";
    },
    contractData() {
      return this.$store.state.prebidPlan.PreBidPlan.ContractChecked
        ? "Yes"
        : "No";
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
