<template>
  <v-container>
    <v-row justify="center">
      <v-expansion-panels accordion v-model="panels" multiple>
        <v-expansion-panel v-for="(item, i) in headers" :key="i">
          <v-expansion-panel-header>
            {{ i + 1 }}. {{ item.name }}</v-expansion-panel-header
          >
          <v-expansion-panel-content v-if="item.name == 'Project details'">
            <ProjectDetailsDisplay />
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="item.name == 'Questionnaire'">
            <QuestionnaireDisplay />
          </v-expansion-panel-content>
          <v-expansion-panel-content v-if="item.name == 'Action Owners'">
            <ActionsDisplay :expandOwners="expandAll" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-row>
  </v-container>
</template>

<script>
import ProjectDetailsDisplay from "@/components/ProjectDetailsDisplay.vue";
import QuestionnaireDisplay from "@/components/QuestionnaireDisplay.vue";
import ActionsDisplay from "@/components/ActionsDisplay.vue";

export default {
  props: ["expandAll"],
  components: {
    ProjectDetailsDisplay,
    QuestionnaireDisplay,
    ActionsDisplay,
  },
  data: () => ({
    panels: [],
    items: 8,
    headers: [
      { name: "Project details" },
      { name: "Questionnaire" },
      { name: "Action Owners" },
    ],
  }),
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
