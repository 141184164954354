<template>
  <div>
    <PrebidNavBar />
    <v-container>
      <PlanHeader :title="'Report'" />
      <v-row>
        <v-col cols="2">
          <v-btn block v-if="expand == false" @click="expand = !expand"
            ><span> Expand All </span> <v-icon right>mdi-chevron-down </v-icon>
          </v-btn>
          <v-btn block v-if="expand == true" @click="expand = !expand">
            <span> Collapse All </span> <v-icon right>mdi-chevron-up </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="lockDIP()">
            <div v-if="$store.state.prebidPlan.PreBidPlan.Lock == true">
              <span>Unlock DIP</span>
              <v-icon right>mdi-lock-open-variant </v-icon>
            </div>
            <div v-else>
              <span> Lock DIP </span> <v-icon right>mdi-lock </v-icon>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="downloadJSON()">Download JSON </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="print()">Print DIP </v-btn>
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col cols="3">
          Last modified by: <strong>{{ lastModified }}</strong>
        </v-col>
      </v-row>

      <div v-if="showWarning">
        <v-row>
          <v-col cols="4">
            <v-alert dense border="left" color="grey darken-1" type="warning">
              You are on the UAT / Test server!
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <div id="printMe">
            <PrebidReportExpansionPanel :expandAll="expand" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2">
          <v-btn dark block color="#E61E28" @click="navigate('PrebidActions')"
            >BACK</v-btn
          >
        </v-col>
        <v-col cols="8"> </v-col>
        <v-col cols="2">
          <v-btn dark block color="#E61E28" @click="navigate('Home')"
            >HOME</v-btn
          >
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import PrebidReportExpansionPanel from "@/components/PrebidReportExpansionPanel.vue";
import PlanHeader from "@/components/PlanHeader.vue";
import NavigationButton from "@/components/NavigationButton.vue";
import PrebidNavBar from "@/components/PrebidNavBar.vue";
import mixin from "../mixins/mixin.js";
export default {
  mixins: [mixin],
  components: {
    PrebidReportExpansionPanel,
    PlanHeader,
    NavigationButton,
    PrebidNavBar,
  },
  data: () => ({
    expand: false,
    loading: false,
    theme:
      "filter: hue-rotate(155deg) sepia(0.2) contrast(160%) saturate(4) brightness(1);",
  }),
  created() {},
  computed: {
    lastModified() {
      return this.$store.state.lastModified;
    },
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    navigate(routeName) {
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
      this.$session.set("planData", this.$store.state.prebidPlan);
      this.$router.push({ name: `${routeName}` });
    },
    lockDIP() {
      if (this.$store.state.plan.PrebidPlan.Lock == null) {
        this.$store.state.plan.PrebidPlan.Lock = true;
      } else {
        this.$store.state.plan.PrebidPlan.Lock =
          !this.$store.state.plan.PrebidPlan.Lock;
      }
      this.prebidUpdateDIP(
        this.$store.state.prebidPlan,
        this.$store.state.ApiUri
      );
    },

    async downloadPreBidJSON() {
      try {
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetPreBidResultFile?ProjectCode=${this.$store.state.prebidPlan.ProjectCode}`,
          { responseType: "blob" }
        );

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${this.$store.state.prebidPlan.ProjectCode}_PREBIDDIP.json`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.log(error.response);
        // Handle error as needed
      }
    },

    print() {
      function printdiv(printdivname) {
        var headstr = "<html><head><title>Booking Details</title></head><body>";
        var footstr = "</body>";
        var newstr = document.getElementById(printdivname).innerHTML;
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = headstr + newstr + footstr;
        window.print();
        document.body.innerHTML = oldstr;
        return false;
      }

      this.expand = true;
      setTimeout(function () {
        printdiv("printMe");
      }, 500);
    },
  },
};
</script>

<style src="../styles/prebidnavbar.scss" lang="scss"></style>
